<template>
  <div class="TodayData">
    <div class="TodayData-header">
      {{ today }}&nbsp;&nbsp;&nbsp;&nbsp;
      星期{{ chnNumChar[week] }}
    </div>
    <div class="TodayData-container">
      <div class="TodayData-container-left">
        <div class="title">今日统计数据</div>
        <el-table :data="tableData">
          <template v-for="colume in columes">
            <el-table-column
              :key="colume.prop"
              :prop="colume.prop"
              :label="colume.label"
            >
              <template slot-scope="scope">
                <template v-if="colume.key === 'money'">
                  {{ formatNum(scope.row[colume.prop], 3) }}
                </template>
                <template v-else>
                  {{ scope.row[colume.prop] }}
                </template>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="TodayData-container-right">
        <DataBoard
          :options="rmbOptions"
        />
        <DataBoard
          :options="jpyOptions"
        />
        <DataBoard
          :options="usdOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';

import { formatNum } from '@/utils';

import DataBoard from '../DataBoard.vue';

export default {
    name: 'TodayData',
    components: {
      DataBoard,
    },
    data() {
      return {
        formatNum,
        today: dayjs().format('YYYY-MM-DD'),
        week: dayjs().day(),
        chnNumChar:  ["日", "一", "二", "三", "四", "五", "六"],
        rmbOptions: {
          title: '历史累计（人民币）',
          subTitle: 'RMB',
          value: 0,
          digit: 3,
          icon: 'line',
          unit: '万元'
        },
        jpyOptions: {
          title: '历史累计（日元）',
          subTitle: 'JPY',
          value: 0,
          digit: 3,
          icon: 'bar',
          unit: '万元'
        },
        usdOptions: {
          title: '历史累计（美元）',
          subTitle: 'USD',
          value: 0,
          digit: 3,
          icon: 'line',
          unit: '万元'
        },
        queryParams: {
          startDate: dayjs().subtract(1, 'day').format("YYYY-MM-DD 00:00:00"),
          endDate: dayjs().subtract(1, 'day').format("YYYY-MM-DD 23:59:59")
        },
        columes: [
          { label: '名称', prop: 'tradeMode', },
          { label: '单量', prop: 'num', },
          { label: '美元USD/万元', prop: 'USD', key: 'money' },
          { label: '日元JPY/万元', prop: 'JPY', key: 'money' },
          { label: '人民币RMB/万元', prop: 'RMB', key: 'money' },
        ],
        tableData: []
      }
    },
    created () {
      this.getData();
      this.getAll();
    },
    methods: {
      async getData() {
        let data9610 = [];
        let data9710 = [];
        const res9610 = await this.$request.get(`/data/businessTypeData/overallQuery`, { params: { tradeMode: 9610, ...this.queryParams } });
        if(res9610.code === 200) {
          data9610 = res9610.data.map(item => {
            return {
              ...item,
              ...item.priceList.reduce((prev, cur) => {
                const { totalPrice, currency, currencyName, } = cur;
                const name = currencyName || currency;
                if(name === '人民币'){
                  prev.RMB = (totalPrice / 10000);
                } else if(name === '美元'){
                  prev.USD = (totalPrice / 10000);
                } else if(name === '日本元'){
                  prev.JPY = (totalPrice / 10000);
                }
                return prev;
              }, {
                USD: 0,
                JPY: 0,
                RMB: 0
              })
            }
          });
        }
        const res9710 = await this.$request.get(`/data/businessTypeData/overallQuery`, { params: { tradeMode: 9710, ...this.queryParams } });
        if(res9710.code === 200) {
          data9710 = res9710.data.map(item => {
            return {
              ...item,
              ...item.priceList.reduce((prev, cur) => {
                const { totalPrice, currency, currencyName, } = cur;
                const name = currencyName || currency;
                if(name === '人民币'){
                  prev.RMB = (totalPrice / 10000);
                } else if(name === '美元'){
                  prev.USD = (totalPrice / 10000);
                } else if(name === '日本元'){
                  prev.JPY = (totalPrice / 10000);
                }
                return prev;
              }, {
                USD: 0,
                JPY: 0,
                RMB: 0
              })
            }
          });
        }
        this.tableData = [ ...data9610, ...data9710 ];
      },
      async getAll() {
        const res = await this.$request.get(`/data/businessTypeData/tradeAmount`, { params: {  } });
        if(res.code === 200) {
          let allData = [];
          Object.values(res.data).forEach(list => {
            allData = [ ...allData, ...list ];
          });
          const totalPrice = allData.reduce((prev, cur) => {
            const { currency, totalPrice } = cur;
            switch(currency) {
              case '人民币':
                prev.RMB += totalPrice;
                break;
              case '美元':
                prev.USD += totalPrice;
                break;
              case '日本元':
                prev.JPY += totalPrice;
                break;
            }
            return prev;
          }, {
            USD: 0,
            JPY: 0,
            RMB: 0
          });
          this.rmbOptions.value = totalPrice.RMB / 10000;
          this.usdOptions.value = totalPrice.USD / 10000;
          this.jpyOptions.value = totalPrice.JPY / 10000
        }
      }
    },
  }

</script>

<style lang="scss" scoped>
.TodayData{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-header{
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background-color: #fff;
    box-shadow: 4px 0px 6px 0px rgba(0,0,0,0.05);
  }
  &-container{
    // flex: 1;
    // height: calc(100vh - 48px);
    padding: 24px 24px;
    background-color: #F5F7F8;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    &-left{
      flex: 1;
      padding: 24px 24px;
      background-color: #fff;
      .title{
        font-size: 16px;
        color: #525252;
        margin-bottom: 24px;
      }
    }
    &-right{
      width: 280px;
      margin-left: 24px;
      .DataBoard{
        margin-top: 24px;
        &:first-child{
          margin-top: 0;
        }
      }
    }
  }
}
</style>